<template>
	<div class="home">
		<img alt="Vue logo" src="../assets/logo.png">
		<br/>
		<social-buttons></social-buttons>
		<!-- <delivery></delivery> -->
	</div>
</template>

<script>
import SocialButtons from '../components/SocialButtons.vue'
// import Delivery from '../components/Delivery.vue'

export default {
	name: 'Home',
	components: {
		SocialButtons,
		// Delivery
	}
}
</script>



