









export default {
	props: [
		'id',
		'title',
		'url',
		'iconGroup',
		'icon'
	],
}
