

























import { Vue } from 'vue-property-decorator';

import SocialButton from './SocialButton.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhoneVolume, faEnvelope, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faInstagram, faFacebookF, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPhoneVolume, faEnvelope, faShoppingCart)
library.add(faWhatsapp, faInstagram, faFacebookF, faTelegram)

Vue.component('font-awesome-icon', FontAwesomeIcon)

export default{
	components: {
		SocialButton
	},
	data: function () {
		return {
			items: [
				[
					{'id': "Instagram", 'title': "Instagram", 'url': "https://www.instagram.com/mbezhanova_handmade/", 'iconGroup': "fab", 'icon': "instagram"},
					{'id': "Facebook", 'title': "Facebook", 'url': "https://www.facebook.com/MBezhanova.Handmade/", 'iconGroup': "fab", 'icon': "facebook-f"},
					{'id': "Shop", 'title': "Shop", 'url': "https://jujuway.com/mbezhanova", 'iconGroup': "fas", 'icon': "shopping-cart"},
				],
				[
					{'id': "Phone", 'title': "Phone", 'url': "tel:79037148372", 'iconGroup': "fas", 'icon': "phone-volume"},
					{'id': "Mail", 'title': "Mail", 'url': "mailto:info@mbezhanova.ru?subject=Здравствуйте.%20Я%20хочу%20сделать%20заказ.", 'iconGroup': "fas", 'icon': "envelope"},
					{'id': "WhatsApp", 'title': "Whatsapp", 'url': "https://wa.me/79037148372?text=Здравствуйте.%20Я%20хочу%20сделать%20заказ.", 'iconGroup': "fab", 'icon': "whatsapp"},
				]
			]
		}
	}
}
